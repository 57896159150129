import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {i18n} from '@lingui/core';
import {cn} from '@zentact/ui-tailwind/utils';
import {Fragment} from 'react';
import {Link} from 'react-router-dom';

export type NavigatableItem = {
  name: string;
  href: string;
};

type Props = {
  user: {
    name?: string;
    picture?: string;
  };
  businessName?: string;
  dropDownItems: NavigatableItem[];
};

export const ProfileDropDown = ({user, businessName, dropDownItems}: Props) => {
  return (
    <Menu as="div" className="relative">
      {({open}) => (
        <>
          <Menu.Button className="flex items-center">
            <span className="sr-only">
              <p>Open user menu</p>
            </span>
            <div className="flex-col flex-wrap hidden mr-4 sm:flex" aria-hidden="true">
              <span className="w-full text-xs text-right text-gray-900">{user.name}</span>
              <span className="w-full text-xs text-right text-gray-500">{businessName}</span>
            </div>
            <img className="w-8 h-8 rounded-full bg-gray-50" src={user.picture} alt={user.name} />
            <span className="hidden sm:flex sm:items-center">
              <ChevronDownIcon
                className={cn('ml-2 h-5 w-5 text-gray-400 transition-all', open && 'rotate-180')}
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              {dropDownItems.map(item => (
                <Menu.Item key={i18n._(item.name)}>
                  {({active}) => (
                    <Link
                      to={item.href}
                      className={cn(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      )}
                    >
                      {i18n._(item.name)}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
