import {trpc} from '@/api/trpcClient';
import {useLocale} from '@/hooks/useLocale';
import {useMemo, useState} from 'react';
import {createContainer} from 'unstated-next';
import {AuthProfile} from '../types/auth-profile';

const Store = createContainer(() => {
  const {localeState, locale} = useLocale();
  const tenant = trpc.tenant.getContextTenant.useQuery();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [authProfile, setAuthProfile] = useState<{isLoading: boolean; data?: AuthProfile}>({
    isLoading: true,
  });

  const organization = trpc.organization.getContextOrganization.useQuery(undefined, {
    enabled: !!accessToken,
    refetchOnWindowFocus: true,
  });

  const merchantAccounts = trpc.merchantAccount.getAllMerchantAccounts.useQuery(undefined, {
    enabled: !!accessToken,
    refetchOnWindowFocus: true,
    initialData: [],
  });

  const activeAndInactiveMerchantAccounts = useMemo(() => {
    return merchantAccounts.data.filter(
      merchant => merchant.status === 'ACTIVE' || merchant.status === 'INACTIVE'
    );
  }, [merchantAccounts.data]);

  return {
    isError: localeState.isError || tenant.isError,
    tenant: tenant.data,
    organization: organization.data,
    organizationRefetch: organization.refetch,
    merchantAccountsRefetch: merchantAccounts.refetch,
    merchantAccounts: merchantAccounts.data,
    activeAndInactiveMerchantAccounts,
    authProfile,
    setAuthProfile,
    localeState,
    locale,
    setAccessToken,
  };
});

export const useStore = () => {
  return Store.useContainer();
};

export const StoreProvider = Store.Provider;
