import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {
  CurrencyCode,
  ErrorCode,
  MerchantAccountPublicStatus,
  formatAmount,
  formatLocaleDate,
  formatPercentage,
  isFormattedTrpcError,
} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  Loading,
  PaymentMethodsPieChart,
  Typography,
  useRequiredParams,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EditAndResendInvitation} from './actions/edit-and-resend-invitation';
import {UpdateMerchantCustomerSupport} from './actions/update-merchant-csutomer-support';
import {UpdateMerchantEmailNotifications} from './actions/update-merchant-email-notifications';
import {UpdateMerchantSurchargeConfiguration} from './actions/update-merchant-surcharge-configuration/update-merchant-surcharge-configuration';
import {MerchantAccountActions} from './merchant-accounts-list/merchant-account-actions';

const getBreadCrumbs = (merchantAccountName?: string) => [
  {name: t`Merchant Accounts`, href: RoutePath.MERCHANT_ACCOUNTS, current: false},
  ...(merchantAccountName ? [{name: merchantAccountName, href: '#', current: true}] : []),
];

const joinAddressParts = (...parts: Array<string | undefined | null>) => {
  const joined = parts.filter(v => !!v).join(' ');
  return joined.length ? joined : undefined;
};

export const MerchantDetails = () => {
  const navigate = useNavigate();
  const {locale} = useLocale();
  const {tenant, authProfile} = useStore();

  const {merchantAccountId} = useRequiredParams<{merchantAccountId: string}>();
  const [isSurchargeConfigurationSidebarOpen, setSurchargeConfigurationSidebarOpen] =
    useState(false);

  const merchantAccount = trpc.merchantAccount.getMerchantAccountById.useQuery(
    {merchantAccountId},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onError: error => {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;
        navigate(RoutePath.ERROR, {state: {errorCode}});
      },
    }
  );

  const merchantAccountProfileStats =
    trpc.merchantAccount.getMerchantAccountProfileStatistics.useQuery(
      {
        merchantAccountId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        keepPreviousData: true,
        onError: error => {
          const errorCode = isFormattedTrpcError(error)
            ? error.data.errorCode
            : ErrorCode.ERROR_GENERIC;
          navigate(RoutePath.ERROR, {state: {errorCode}});
        },
      }
    );

  const {
    data: editAndResendRow,
    on: openEditAndResendPanel,
    off: closeEditAndResendPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();
  const {
    data: updateCustomerSupportRow,
    on: openUpdateCustomerSupportPanel,
    off: closeUpdateCustomerSupportPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();
  const {
    data: updateEmailNotificationsRow,
    on: openUpdateEmailNotificationsPanel,
    off: closeUpdateEmailNotificationsPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  if (
    merchantAccount.isLoading ||
    !merchantAccount.data ||
    merchantAccountProfileStats.isLoading ||
    !merchantAccountProfileStats.data
  ) {
    return (
      <div className="flex items-center justify-center h-80">
        <Loading mode="inline" />
      </div>
    );
  }

  const {brandConfiguration, onboardedAt} = merchantAccount.data;
  const currency = tenant?.currency as CurrencyCode;
  const showSurchargesSection =
    tenant?.features?.enableSurcharges &&
    merchantAccount.data.status === MerchantAccountPublicStatus.ACTIVE;
  const isSurchargeConfigurationEditable =
    showSurchargesSection && authProfile.data?.isOrganizationAdmin;
  const {sessionId, userId, firstName, lastName, inviteeEmail} =
    merchantAccount.data.merchantAccountRegistration;

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs(merchantAccount.data.businessName)} />
      <div className="flex items-center justify-between my-4">
        <Typography variant="header-page" className="flex">
          <Trans>{merchantAccount.data.businessName}</Trans>
        </Typography>
        <div className="ml-16">
          <MerchantAccountActions
            merchantAccountRow={merchantAccount.data}
            registrationSessionId={sessionId}
            userId={userId}
            inviteeEmail={inviteeEmail || ''}
            businessName={merchantAccount.data.businessName || ''}
            refetchMerchantAccountsList={merchantAccount.refetch}
            openEditAndResendPanel={openEditAndResendPanel}
            openUpdateCustomerSupportPanel={openUpdateCustomerSupportPanel}
            openUpdateEmailNotificationsPanel={openUpdateEmailNotificationsPanel}
            openUpdateSurchargeConfigurationPanel={
              isSurchargeConfigurationEditable
                ? () => setSurchargeConfigurationSidebarOpen(true)
                : undefined
            }
            viewType="chevron-button"
          />
        </div>
      </div>
      {onboardedAt && (
        <div className="grid gap-4 pb-5 max-md:gap-4 md:grid-cols-3">
          <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
            <div className="mb-3 leading-6 text-gray-600 text-medium ">
              <Trans>Last Process Date</Trans>
            </div>
            <div className="text-3xl font-bold text-gray-800">
              {merchantAccountProfileStats.data.lastPaymentDate
                ? formatLocaleDate(
                    merchantAccountProfileStats.data.lastPaymentDate,
                    DateTime.DATE_MED
                  )
                : '-'}
            </div>
          </div>
          <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
            <div className="mb-3 leading-6 text-gray-600 text-medium ">
              <Trans>All Time Processed</Trans>
            </div>
            <div className="text-3xl font-bold text-gray-800">
              {formatAmount(merchantAccountProfileStats.data.totalTxVolume, locale, currency)}
            </div>
          </div>
          <div className="p-5 bg-white shadow rounded-xl md:col-span-1">
            <div className="mb-3 leading-6 text-gray-600 text-medium ">
              <Trans>Net Proceeds</Trans>
            </div>
            <div className="text-3xl font-bold text-gray-800">
              {formatAmount(merchantAccountProfileStats.data.totalProfit, locale, currency)}
            </div>
          </div>
        </div>
      )}
      {merchantAccountProfileStats.data.totalTxCount > 0 && (
        <div className="py-5 mb-5 bg-white shadow rounded-xl">
          <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
            <Typography variant="header-section">
              <Trans>Transaction Statistics</Trans>
            </Typography>
          </div>
          <div className="grid gap-4 px-4 md:grid-cols-2 sm:px-6">
            <div className="leading-6 border-gray-100 sm:col-span-1">
              <dl className="divide-y divide-gray-100">
                <div className="pb-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Monthly Volume</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.avergeMonthlyVolume,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Transaction Count</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccountProfileStats.data.totalTxCount}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Transaction Amount</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.averageTxAmount,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Count</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccountProfileStats.data.totalChargebackCount}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Volume</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.totalChargebackVolume,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Average Chargeback Amount</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatAmount(
                      merchantAccountProfileStats.data.averageChargebackAmount,
                      locale,
                      currency
                    )}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Chargeback Percentage</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {formatPercentage(
                      merchantAccountProfileStats.data.totalTxCount,
                      merchantAccountProfileStats.data.totalChargebackCount,
                      locale
                    )}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="md:col-span-1">
              <PaymentMethodsPieChart
                paymentMethods={merchantAccountProfileStats.data.paymentMethodStats}
                locale={locale}
                primaryColorHex={tenant?.brandConfiguration.primaryColorHex}
              />
            </div>
          </div>
        </div>
      )}
      <div className="py-5 mb-5 bg-white shadow rounded-xl">
        <div className="px-4 pb-5 mb-5 border-b border-gray-200 sm:px-6">
          <Typography variant="header-section">
            <Trans>General</Trans>
          </Typography>
        </div>
        <div className="px-4 sm:px-6">
          <div className="leading-6 border-gray-100 ">
            <dl className="divide-y divide-gray-100">
              <div className="pb-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Merchant Account Name</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {merchantAccount.data.businessName}
                </dd>
              </div>
              {firstName && lastName && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Owner</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {`${firstName} ${lastName}`}
                  </dd>
                </div>
              )}
              {inviteeEmail && (
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Contact Email</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">{inviteeEmail}</dd>
                </div>
              )}
              <div className="py-3 sm:flex">
                <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                  <Trans>Last Updated At</Trans>
                </dt>
                <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                  {formatLocaleDate(merchantAccount.data.updatedAt)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      {merchantAccount.data.status === MerchantAccountPublicStatus.ACTIVE && brandConfiguration && (
        <>
          <div className="py-5 mb-5 bg-white shadow rounded-xl">
            <div className="px-4 mb-5 sm:px-6">
              <Typography variant="header-section" className="mb-2">
                <Trans>Customer Support Information</Trans>
              </Typography>
              <div className="pb-2 text-sm text-gray-600 border-b border-gray-200">
                <Trans>
                  This will be shown to customers on the checkout page and on email notifications.
                </Trans>
              </div>
            </div>
            <div className="px-4 sm:px-6">
              <div className="leading-6 border-gray-100 ">
                <dl className="divide-y divide-gray-100">
                  <div className="pb-3 sm:flex">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                      <Trans>Phone Number</Trans>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                      {brandConfiguration.phoneNumber || 'N/A'}
                    </dd>
                  </div>
                  <div className="py-3 sm:flex">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                      <Trans>Support Email</Trans>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                      {brandConfiguration.supportEmail || 'N/A'}
                    </dd>
                  </div>
                  <div className="py-3 sm:flex">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                      <Trans>Address</Trans>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                      {!!brandConfiguration.businessAddressLine1 && (
                        <div>{brandConfiguration.businessAddressLine1}</div>
                      )}
                      {!!brandConfiguration.businessAddressLine2 && (
                        <div>{brandConfiguration.businessAddressLine2}</div>
                      )}
                      <div>
                        {joinAddressParts(
                          brandConfiguration.businessAddressCity
                            ? `${brandConfiguration.businessAddressCity},`
                            : '',
                          brandConfiguration.businessAddressState,
                          brandConfiguration.businessAddressPostalCode
                        )}
                      </div>
                      {!!brandConfiguration.businessAddressCountry && (
                        <div>{brandConfiguration.businessAddressCountry}</div>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div className="py-5 mb-5 bg-white shadow rounded-xl">
            <div className="px-4 mb-5 sm:px-6">
              <Typography variant="header-section" className="mb-2">
                <Trans>Email Notifications</Trans>
              </Typography>
              <div className="pb-2 text-sm text-gray-600 border-b border-gray-200">
                <Trans>
                  Customers automatically receive transaction emails. This email additionally
                  receives copies.
                </Trans>
              </div>
            </div>
            <div className="px-4 sm:px-6">
              <div className="leading-6 border-gray-100 ">
                <dl className="divide-y divide-gray-100">
                  <div className="pb-3 sm:flex">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                      <Trans>Notification Email</Trans>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                      {brandConfiguration.notificationEmail ?? 'N/A'}
                    </dd>
                  </div>
                  <div className="py-3 sm:flex">
                    <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                      <Trans>Enabled For</Trans>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                      {!!brandConfiguration.sendMerchantPaymentEmailNotification && (
                        <div>
                          <Trans>Payments</Trans>
                        </div>
                      )}
                      <div>
                        <Trans>Refunds</Trans>
                      </div>
                      <div>
                        <Trans>Chargebacks</Trans>
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </>
      )}
      {showSurchargesSection && (
        <div className="py-5 mb-5 bg-white shadow rounded-xl">
          <div className="px-4 mb-5 sm:px-6">
            <div className="flex items-center justify-between mb-2">
              <Typography variant="header-section">
                <Trans>Surcharge Configuration</Trans>
              </Typography>
              {isSurchargeConfigurationEditable && (
                <Button
                  variant="primary"
                  className="w-fit"
                  onClick={() => setSurchargeConfigurationSidebarOpen(true)}
                >
                  <Trans>Update</Trans>
                </Button>
              )}
            </div>

            <div className="pb-2 text-sm text-gray-600 border-b border-gray-200">
              <Trans>
                The Surcharge represents an additional charge applied to the final payment amount.
                This fee can be calculated as a percentage of the original payment amount or as a
                flat value, and in some cases, both methods may be applied simultaneously.
              </Trans>
            </div>
          </div>
          <div className="px-4 sm:px-6">
            <div className="leading-6 border-gray-100 ">
              <dl className="divide-y divide-gray-100">
                <div className="pb-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Credit Card Percentage Fee</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccount.data.surchargeConfiguration?.creditCardPercentageFee ?? 0}%
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Credit Card Flat Fee</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccount.data.surchargeConfiguration?.creditCardFlatFee
                      ? formatAmount(
                          merchantAccount.data.surchargeConfiguration.creditCardFlatFee,
                          locale,
                          currency
                        )
                      : '-'}
                  </dd>
                </div>
                <div className="py-3 sm:flex">
                  <dt className="text-sm font-medium text-gray-900 sm:w-[200px] sm:mr-6">
                    <Trans>Interac Flat Fee</Trans>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-700 break-words sm:mt-0">
                    {merchantAccount.data.surchargeConfiguration?.interacFlatFee
                      ? formatAmount(
                          merchantAccount.data.surchargeConfiguration.interacFlatFee,
                          locale,
                          currency
                        )
                      : '-'}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}
      {editAndResendRow && (
        <EditAndResendInvitation
          merchantAccountRow={editAndResendRow}
          onClose={closeEditAndResendPanel}
          isOpen={!!editAndResendRow}
          refetchMerchantAccountsList={merchantAccount.refetch}
        />
      )}
      {updateCustomerSupportRow && (
        <UpdateMerchantCustomerSupport
          merchantAccountRow={updateCustomerSupportRow}
          onClose={closeUpdateCustomerSupportPanel}
          isOpen={!!updateCustomerSupportRow}
          triggerRefetch={merchantAccount.refetch}
        />
      )}
      {updateEmailNotificationsRow && (
        <UpdateMerchantEmailNotifications
          merchantAccountRow={updateEmailNotificationsRow}
          onClose={closeUpdateEmailNotificationsPanel}
          isOpen={!!updateEmailNotificationsRow}
          triggerRefetch={merchantAccount.refetch}
        />
      )}
      <UpdateMerchantSurchargeConfiguration
        merchantAccountRow={merchantAccount.data}
        isOpen={isSurchargeConfigurationSidebarOpen}
        triggerRefetch={merchantAccount.refetch}
        onClose={() => setSurchargeConfigurationSidebarOpen(false)}
      />
    </div>
  );
};
