import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import {ServerRouter} from '@zentact/api';
import {ShopperListItemOutput} from '@zentact/api/src/trpc/routers/savedPaymentMethodRouter';
import {CurrencyCode, ErrorCode, isFormattedTrpcError, toMinorUnits} from '@zentact/common';
import {
  AddNewSavedPaymentMethodForm,
  AddNewSavedPaymentMethodFormData,
  Button,
  CheckoutIFrame,
  SlideOverWithBrandedHeader,
  getAddNewSavedPaymentMethodFormSchema,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';

type AddNewSavedPaymentMethodPanelProps = {
  isOpen: boolean;
  onCancel: () => void;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  refetch: () => void;
  expectedOrigin?: string;
  setPaymentMethodRow: React.Dispatch<React.SetStateAction<ShopperListItemOutput | null>>;
};

export const AddNewSavedPaymentMethod = ({
  isOpen,
  onCancel,
  trpc,
  refetch,
  expectedOrigin,
  setPaymentMethodRow,
}: AddNewSavedPaymentMethodPanelProps) => {
  const {tenant, locale} = useStore();
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const [checkoutUrlState, setCheckoutUrl] = useState('');
  const [isLoading, setLoading] = useState(false);

  const {minAmount = 100, maxAmount = 100000} = tenant?.checkoutConfiguration || {};
  const currency = tenant?.currency as CurrencyCode;

  const form = useForm<AddNewSavedPaymentMethodFormData>({
    resolver: zodResolver(
      getAddNewSavedPaymentMethodFormSchema({i18n, minAmount, maxAmount, currency, locale})
    ),
    defaultValues: {
      customerId: '',
      customerEmail: '',
    },
  });
  const {merchantAccounts} = useStore();
  const activeMerchantAccounts = merchantAccounts?.filter(({status}) => status === 'ACTIVE') ?? [];

  const trpcContext = trpc.useUtils();

  const handleAddPaymentMethod = useCallback(
    async (data: AddNewSavedPaymentMethodFormData) => {
      const {customerId: merchantShopperId, customerEmail: shopperEmail, merchantAccountId} = data;
      const preauthorizeAmount = data.preauthorizeAmount
        ? toMinorUnits(data.preauthorizeAmount, currency)
        : 0;
      setLoading(true);
      try {
        const shopper = await trpcContext.client.savedPaymentMethod.createShopper.mutate({
          merchantAccountId,
          shopperEmail,
          merchantShopperId,
        });
        onCancel();
        refetch();
        const {checkoutUrl} = await trpcContext.client.checkout.startCheckout.mutate({
          amount: preauthorizeAmount || 0,
          authorizationType: preauthorizeAmount ? 'PRE_AUTH_CANCELLED' : 'REGULAR',
          shopper: {
            id: shopper.merchantShopperId,
            email: shopperEmail,
          },
          currency: currency as CurrencyCode,
          merchantAccountId,
          savePaymentMethod: 'addEmbed',
        });
        setPaymentMethodRow({...shopper, lastCharged: null});
        setCheckoutUrl(checkoutUrl);
      } catch (error) {
        const errorCode = isFormattedTrpcError(error)
          ? error.data.errorCode
          : ErrorCode.ERROR_GENERIC;

        if (errorCode === ErrorCode.SHOPPER_ALREADY_EXISTS) {
          form.setError('customerId', {
            type: 'manual',
            message: t`Customer with the same ID already exists`,
          });
        }
        showErrorNotification(
          t`Failed to add new stored payment method`,
          t`Something went wrong. Please try again later.`
        );
      }
      setLoading(false);
    },
    [onCancel, refetch, trpcContext, showErrorNotification, currency]
  );

  const onCancelClick = useCallback((_event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel();
    form.reset();
  }, []);

  return (
    <>
      <SlideOverWithBrandedHeader
        isOpen={isOpen}
        title={t`Add New Saved Payment Method`}
        text={t`Add new saved payment method for customer without transaction.`}
        closeHandler={onCancelClick}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={form.handleSubmit(handleAddPaymentMethod)}
                isLoading={isLoading}
              >
                <Trans>Add</Trans>
              </Button>
            </div>
            <Button variant="secondary" size="lg" className="w-fit" onClick={onCancelClick}>
              <Trans>Close</Trans>
            </Button>
          </footer>
        }
      >
        <form onSubmit={form.handleSubmit(handleAddPaymentMethod)}>
          <AddNewSavedPaymentMethodForm
            form={form}
            merchantAccounts={activeMerchantAccounts}
            minAmount={minAmount}
            maxAmount={maxAmount}
            currency={currency}
            locale={locale}
          />
        </form>
      </SlideOverWithBrandedHeader>
      {checkoutUrlState && (
        <CheckoutIFrame
          checkoutUrl={checkoutUrlState}
          expectedOrigin={expectedOrigin}
          onClose={() => setCheckoutUrl('')}
          onSuccess={() => {
            refetch();
            showSuccessNotification(i18n._('Adding new payment method...'));
          }}
        />
      )}
    </>
  );
};
