import {withAuthenticationRequired} from '@auth0/auth0-react';
import {Bars3Icon} from '@heroicons/react/24/outline';
import {
  Loading,
  LogoOrganization,
  NavigationItem,
  ProfileDropDown,
  Sidebar,
  SidebarContent,
} from '@zentact/ui-tailwind';
import {useState} from 'react';
import {Outlet} from 'react-router-dom';

type Props = {
  user?: {
    name?: string;
    picture?: string;
  };
  brandName?: string;
  brandLogoUrl?: string | null;
  organizationName?: string;
  organizationLogoUrl?: string | null;
  navItems: NavigationItem[];
  profileDropDownItems: NavigationItem[];
};

export function Layout({
  user,
  brandName,
  brandLogoUrl,
  organizationName,
  organizationLogoUrl,
  navItems,
  profileDropDownItems,
}: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (!user) {
    return <Loading />;
  }

  return (
    <div>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
        <SidebarContent
          logoUrl={brandLogoUrl}
          displayName={brandName}
          navItems={navItems}
          onNavigate={() => setSidebarOpen(false)}
        />
      </Sidebar>
      <div className="xl:pl-72">
        <div className="sticky top-0 z-20 flex items-center h-16 px-4 bg-white border-b border-gray-200 shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 xl:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 xl:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>
          <div className="flex self-stretch flex-1 gap-x-4 xl:gap-x-6">
            <LogoOrganization
              className="items-center flex-1"
              orgName={organizationName}
              orgLogoUrl={organizationLogoUrl}
            />
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <ProfileDropDown
                user={user}
                businessName={organizationName}
                dropDownItems={profileDropDownItems}
              />
            </div>
          </div>
        </div>
        <main className="min-h-screen px-4 py-10 bg-gray-100 sm:px-6 lg:px-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export const ProtectedLayout = withAuthenticationRequired(Layout);
